// src/pages/PublicNewsletter.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

function PublicNewsletter() {
  const [loading, setLoading] = useState(true);
  const { city, id } = useParams();

  useEffect(() => {
    // Redirect to the actual API endpoint
    window.location.href = `/api/${city}/newsletters/${id}/view/`;
  }, [city, id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return null;
}

export default PublicNewsletter;