// Login.js
import React, { useState } from 'react';
import { 
  Container, 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Box 
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, loading, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Removed currentCity parameter since it's handled by the interceptor
    const success = await login(email, password);
    if (success) {
      if (isAdmin()) {
        navigate(`/${currentCity}/dashboard`);
      } else {
        navigate(`/${currentCity}`);
      }
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>
          
          {error && (
            <Typography color="error" align="center" gutterBottom>
              {error}
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? 'Loading...' : 'Login'}
            </Button>
          </form>
          
          <Typography align="center">
            Don't have an account?{' '}
            <Link to={`/${currentCity}/signup`}>Sign Up</Link>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}

export default Login;