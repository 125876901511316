import React, { useState } from 'react';
import { 
  Box, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Link, useLocation, useParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';

const drawerWidth = 240;

function AdminLayout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { city } = useParams();
  const currentCity = city || 'nashville';

  const menuItems = [
    { 
      text: 'Admin Dashboard', 
      icon: <DashboardIcon />, 
      path: `/${currentCity}/dashboard` 
    },
    { 
      text: 'Events', 
      icon: <EventIcon />, 
      path: `/${currentCity}/events/manage` 
    },
    { 
      text: 'Newsletter', 
      icon: <MailIcon />, 
      path: `/${currentCity}/newsletters/manage` 
    },
    { 
      text: 'Posts', 
      icon: <ArticleIcon />, 
      path: `/${currentCity}/posts/manage` 
    },
    { 
      text: 'Companies', 
      icon: <BusinessIcon />, 
      path: `/${currentCity}/companies/manage` 
    },
    { 
      text: 'Settings', 
      icon: <SettingsIcon />, 
      path: `/${currentCity}/settings/manage` 
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
            onClick={isMobile ? handleDrawerToggle : undefined}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                '& .MuiListItemIcon-root': {
                  color: 'white',
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? 'white' : 'inherit' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const mobileNav = (
    <BottomNavigation
      value={menuItems.findIndex(item => item.path === location.pathname)}
      showLabels
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        zIndex: theme.zIndex.drawer + 2,
        height: '56px',
        '& .MuiBottomNavigationAction-root': {
          padding: '6px 0',
          minWidth: 'auto',
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.625rem',
            '&.Mui-selected': {
              fontSize: '0.675rem'
            }
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.25rem'
          }
        }
      }}
    >
      {menuItems.map((item) => (
        <BottomNavigationAction
          key={item.text}
          label={item.text.split(' ')[1] || item.text}
          icon={item.icon}
          component={Link}
          to={item.path}
          sx={{
            maxWidth: '76px'
          }}
        />
      ))}
    </BottomNavigation>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {!isMobile && (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              display: { xs: 'none', sm: 'block' },
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
                top: '64px',
                height: 'calc(100% - 64px)',
              },
            }}
          >
            {drawer}
          </Drawer>
        )}

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: 'none' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              top: '56px',
              height: 'calc(100% - 56px)',
            },
          }}
        >
          {drawer}
        </Drawer>

        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, // can reduce or remove if causing layout issues
            width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
            mb: isMobile ? '56px' : 0,
            pt: isMobile ? '64px' : '56px',
            // Removed overflow: 'hidden' to allow natural spacing
          }}
        >
          {children}
        </Box>

        {isMobile && mobileNav}
      </Box>
    </LocalizationProvider>
  );
}

export default AdminLayout;
