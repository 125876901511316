import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Switch,
  FormControlLabel,
  Avatar,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';

function EditCompany() {
  const { slug, city } = useParams();
  const navigate = useNavigate();
  const currentCity = city || 'nashville';
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [types, setTypes] = useState([]);
  
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    instagram: '',
    facebook: '',
    logo: null,
    featured_image: null,
    is_active: true
  });

  const [preview, setPreview] = useState({
    logo_url: null,
    featured_image_url: null
  });

  // Cleanup effect for image preview URLs
  useEffect(() => {
    const createdUrls = [];
    
    return () => {
      createdUrls.forEach(url => {
        if (url.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, []);

  useEffect(() => {
    Promise.all([fetchCompanyTypes(), fetchCompany()]);
  }, [slug, currentCity]);

  const fetchCompanyTypes = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/companies/types/`);
      setTypes(response.data);
    } catch (error) {
      console.error('Error fetching company types:', error);
      setError('Failed to load company types');
    }
  };

  const fetchCompany = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/companies/${slug}/`);
      const companyData = response.data;
      console.log('Fetched company data:', companyData);
      
      setFormData({
        name: companyData.name || '',
        type: companyData.type || '',
        description: companyData.description || '',
        address: companyData.address || '',
        phone: companyData.phone || '',
        email: companyData.email || '',
        website: companyData.website || '',
        instagram: companyData.instagram || '',
        facebook: companyData.facebook || '',
        logo: null,
        featured_image: null,
        is_active: companyData.is_active
      });

      setPreview({
        logo_url: companyData.logo_url,
        featured_image_url: companyData.featured_image_url
      });
    } catch (error) {
      console.error('Error fetching company:', error);
      setError('Failed to load company');
    } finally {
      setLoading(false);
    }
  };

  // Also update handleChange to ensure proper file handling:
  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    
    if (files && files[0]) {
      const file = files[0];
      console.log(`File selected for ${name}:`, file);
  
      // Create preview URL
      const imageUrl = URL.createObjectURL(file);
      
      // Update preview state
      setPreview(prev => ({
        ...prev,
        [`${name}_url`]: imageUrl
      }));
  
      // Update form data
      setFormData(prev => ({
        ...prev,
        [name]: file
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  // Replace your current handleSubmit with this version:
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      // Create a new FormData instance
      const data = new FormData();
  
      // Append all non-file fields
      data.append('name', formData.name);
      data.append('type', formData.type);
      data.append('description', formData.description);
      data.append('address', formData.address);
      data.append('phone', formData.phone || '');
      data.append('email', formData.email || '');
      data.append('website', formData.website || '');
      data.append('instagram', formData.instagram || '');
      data.append('facebook', formData.facebook || '');
      data.append('is_active', formData.is_active.toString());
  
      // Append files
      if (formData.logo instanceof File) {
        data.append('logo', formData.logo);
        console.log('Appending logo:', formData.logo);
      }
  
      if (formData.featured_image instanceof File) {
        data.append('featured_image', formData.featured_image);
        console.log('Appending featured_image:', formData.featured_image);
      }
  
      // Log FormData entries
      for (let [key, value] of data.entries()) {
        console.log('FormData Entry:', key, value instanceof File ? value.name : value);
      }
  
      // Make the request using fetch instead of axios
      const token = localStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/${currentCity}/companies/${slug}/`;
      
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          // Don't set Content-Type, let browser handle it
        },
        body: data
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to update company');
      }
  
      const responseData = await response.json();
      console.log('Update successful:', responseData);
  
      navigate(`/${currentCity}/companies/manage`);
    } catch (error) {
      console.error('Update failed:', error);
      setError(error.message || 'Failed to update company');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Edit Company
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Company Name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Company Type</InputLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  disabled={loading}
                  label="Company Type"
                >
                  {types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address"
                label="Address"
                value={formData.address}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="phone"
                label="Phone"
                value={formData.phone}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="website"
                label="Website"
                value={formData.website}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="instagram"
                label="Instagram URL"
                value={formData.instagram}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="facebook"
                label="Facebook URL"
                value={formData.facebook}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            {/* Logo Upload Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                {preview.logo_url && (
                  <Avatar
                    src={preview.logo_url}
                    alt="Company Logo"
                    sx={{ width: 100, height: 100, mb: 2 }}
                  />
                )}
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  disabled={loading}
                >
                  {preview.logo_url ? 'Change Logo' : 'Upload Logo'}
                  <input
                    type="file"
                    hidden
                    name="logo"
                    onChange={(e) => {
                      console.log('Logo file selected:', e.target.files[0]);
                      handleChange(e);
                    }}
                    accept="image/*"
                  />
                </Button>
                {formData.logo && (
                  <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    New logo selected: {formData.logo.name}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Featured Image Upload Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                {preview.featured_image_url && (
                  <Box
                    component="img"
                    src={preview.featured_image_url}
                    alt="Featured Image"
                    sx={{ 
                      width: '100%', 
                      maxHeight: 200, 
                      objectFit: 'cover',
                      borderRadius: 1,
                      mb: 2
                    }}
                  />
                )}
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  disabled={loading}
                >
                  {preview.featured_image_url ? 'Change Featured Image' : 'Upload Featured Image'}
                  <input
                    type="file"
                    hidden
                    name="featured_image"
                    onChange={(e) => {
                      console.log('Featured image file selected:', e.target.files[0]);
                      handleChange(e);
                    }}
                    accept="image/*"
                  />
                </Button>
                {formData.featured_image && (
                  <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    New image selected: {formData.featured_image.name}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_active}
                    onChange={handleChange}
                    name="is_active"
                    color="primary"
                    disabled={loading}
                  />
                }
                label="Active"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Update Company'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/${currentCity}/companies/manage`)}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default EditCompany;