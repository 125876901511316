import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Box,
  Alert,
  MenuItem,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
  Grid
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient, useAuth } from '../../context/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreatePost = () => {
  const navigate = useNavigate();
  const { city } = useParams();
  const { user } = useAuth();
  const currentCity = city || 'nashville';
  
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    type: '',
    link: '',
    image: null,
    featured: false
  });
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['blockquote', 'link'],
      ['clean']
    ]
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'blockquote', 'link'
  ];
  const [postTypes, setPostTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPostTypes = async () => {
      try {
        console.log('Fetching post types for city:', currentCity);
        const response = await apiClient.get(`/${currentCity}/posts/types/`);
        console.log('Post types response:', response.data);
        setPostTypes(response.data);
      } catch (error) {
        console.error('Error fetching post types:', error.response || error);
        setError(error.response?.data?.detail || 'Error loading post types');
        if (error.response?.status === 401) {
          navigate(`/${currentCity}/login`);
        }
      }
    };

    if (!user) {
      navigate(`/${currentCity}/login`);
    } else {
      fetchPostTypes();
    }
  }, [currentCity, user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        image: e.target.files[0]
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title || !formData.content || !formData.type) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError('');

    const data = new FormData();
    
    // Append all form fields
    Object.keys(formData).forEach(key => {
      if (formData[key] !== null && formData[key] !== '') {
        if (key === 'featured') {
          data.append(key, formData[key].toString());
        } else {
          data.append(key, formData[key]);
        }
      }
    });

    try {
      console.log('Creating post for city:', currentCity);
      const response = await apiClient.post(`/${currentCity}/posts/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log('Post created successfully:', response.data);
      navigate(`/${currentCity}/posts/manage`);
    } catch (error) {
      console.error('Error creating post:', error);
      if (error.response?.data) {
        // Handle structured error responses
        const errorMessage = typeof error.response.data === 'object'
          ? Object.values(error.response.data).flat().join('. ')
          : error.response.data;
        setError(errorMessage);
      } else {
        setError('Failed to create post. Please try again.');
      }
      
      if (error.response?.status === 401) {
        navigate(`/${currentCity}/login`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create Post
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Post Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            disabled={loading}
            error={error && !formData.title}
            helperText={error && !formData.title ? "Title is required" : ""}
          />

<Box sx={{ mt: 2, mb: 2 }}>
  <Typography variant="subtitle2" gutterBottom>
    Content *
  </Typography>
  <ReactQuill
    theme="snow"
    value={formData.content}
    onChange={(content) => setFormData(prev => ({ ...prev, content }))}
    modules={modules}
    formats={formats}
    style={{ height: '200px', marginBottom: '50px' }}
  />
  {error && !formData.content && (
    <Typography color="error" variant="caption">
      Content is required
    </Typography>
  )}
</Box>

          <TextField
            select
            margin="normal"
            required
            fullWidth
            id="type"
            label="Post Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            disabled={loading || postTypes.length === 0}
            error={error && !formData.type}
            helperText={error && !formData.type ? "Post type is required" : ""}
          >
            {postTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            fullWidth
            id="link"
            label="External Link (Optional)"
            name="link"
            value={formData.link || ''}
            onChange={handleChange}
            helperText="Add affiliate links, sources, or resources"
            placeholder="https://example.com"
            type="url"
            disabled={loading}
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Add Image (Optional)
            </Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              disabled={loading}
              style={{ display: 'block', width: '100%' }}
            />
          </Box>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.featured}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    featured: e.target.checked
                  }))}
                  name="featured"
                  color="primary"
                  disabled={loading}
                />
              }
              label="Featured Post"
            />
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading || !user}
              sx={{ flex: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Create Post'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate(`/${currentCity}/posts/manage`)}
              sx={{ flex: 1 }}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default CreatePost;