import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container } from '@mui/material';
import { apiClient } from '../context/AuthContext';

const Hero = () => {
  const [heroImage, setHeroImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { city } = useParams();
  const currentCity = city || 'nashville';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        // Fixed URL to match backend pattern
        const response = await apiClient.get(`/${currentCity}/settings/site-settings/`);
        
        console.log('Hero settings response:', response.data); // Debug log
        
        if (response.data?.hero_images?.length > 0) {
          // Filter active images
          const activeImages = response.data.hero_images.filter(img => img.is_active);
          console.log('Active images:', activeImages); // Debug log
          
          if (activeImages.length > 0) {
            // Select random image from active images
            const randomImage = activeImages[Math.floor(Math.random() * activeImages.length)];
            setHeroImage({
              url: isMobile ? randomImage.mobile_url : randomImage.optimized_url,
              id: randomImage.id,
              title: randomImage.title,
              subtitle: randomImage.subtitle
            });
          }
        }
      } catch (error) {
        console.error('Error fetching hero settings:', error);
      }
    };

    if (currentCity) {
      fetchSettings();
    }
  }, [isMobile, currentCity]);

  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        py: 8,
        mb: 6,
        background: heroImage
          ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${heroImage.url}")`
          : 'primary.main',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '250px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transition: 'background-image 0.3s ease-in-out',
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          position: 'relative',
          zIndex: 1,
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            mb: 3,
            fontSize: { xs: '2.5rem', md: '3.75rem' },
          }}
        >
          {heroImage?.title || `${currentCity.charAt(0).toUpperCase() + currentCity.slice(1)} Fitness Events`}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          paragraph
          sx={{
            maxWidth: '600px',
            mx: 'auto',
            mb: 4,
            fontSize: { xs: '1.25rem', md: '1.5rem' },
          }}
        >
          {heroImage?.subtitle || `Find fitness events in ${currentCity.charAt(0).toUpperCase() + currentCity.slice(1)}`}
        </Typography>

        {/* Beehiiv Embedded Form */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
          }}
        >
          <iframe
            src="https://embeds.beehiiv.com/49daa6c6-b056-488b-a103-52a8e983b0aa?slim=true"
            data-test-id="beehiiv-embed"
            height="52"
            frameBorder="0"
            scrolling="no"
            style={{
              margin: 0,
              borderRadius: '0px',
              backgroundColor: 'transparent',
              width: '100%',
              maxWidth: '500px',
            }}
          ></iframe>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;