import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { EventAvailable, Star, TrendingUp, CalendarMonth } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { apiClient } from '../context/AuthContext';

const StatCard = ({ title, value, icon, loading, color }) => (
    <Paper 
      elevation={2} 
      sx={{ 
        p: 3,
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `${color}.lighter`,
        '&:hover': {
          backgroundColor: `${color}.light`,
          transform: 'translateY(-2px)',
          transition: 'all 0.3s'
        }
      }}
    >
      <Box 
        sx={{ 
          backgroundColor: `${color}.light`,
          borderRadius: '50%',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2
        }}
      >
        {icon}
      </Box>
      <Typography variant="h4" component="div">
        {loading ? <CircularProgress size={20} /> : value}
      </Typography>
      <Typography color="text.secondary" variant="subtitle2" align="center">
        {title}
      </Typography>
    </Paper>
  );
  

export default function EventStatistics() {
  const [stats, setStats] = useState({
    total: 0,
    upcoming: 0,
    featured: 0,
    thisMonth: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { city } = useParams();
  const currentCity = city || 'nashville';

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/${currentCity}/events/`);
        const events = response.data;
        
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        setStats({
          total: events.length,
          upcoming: events.filter(event => new Date(event.date_time) > now).length,
          featured: events.filter(event => event.featured).length,
          thisMonth: events.filter(event => {
            const eventDate = new Date(event.date_time);
            return eventDate >= firstDayOfMonth && eventDate <= lastDayOfMonth;
          }).length
        });
      } catch (err) {
        console.error('Error fetching event stats:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [currentCity]);

  if (error) {
    return (
      <Typography color="error" align="center">
        Failed to load event statistics
      </Typography>
    );
  }

  return (
    // Optional additional padding inside EventStatistics to control spacing
    <Box sx={{ px: 0 }}>
      <Grid container spacing={4}>
        <Grid item xs={6} md={3}>
          <StatCard
            title="Total Events"
            value={stats.total}
            icon={<EventAvailable sx={{ color: 'primary.main' }} />}
            loading={loading}
            color="primary"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatCard
            title="Upcoming Events"
            value={stats.upcoming}
            icon={<TrendingUp sx={{ color: 'success.main' }} />}
            loading={loading}
            color="success"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatCard
            title="Featured Events"
            value={stats.featured}
            icon={<Star sx={{ color: 'warning.main' }} />}
            loading={loading}
            color="warning"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <StatCard
            title="Events This Month"
            value={stats.thisMonth}
            icon={<CalendarMonth sx={{ color: 'info.main' }} />}
            loading={loading}
            color="info"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
