import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import EventIcon from '@mui/icons-material/Event';
import StorefrontIcon from '@mui/icons-material/Storefront';

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { city } = useParams(); // Get current city from URL
  const currentCity = city || 'nashville'; // Default to nashville if no city in URL

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate(`/${currentCity}`); // Redirect to current city home
  };

  // Helper for city-aware navigation
  const getCityPath = (path) => `/${currentCity}${path}`;

  return (
    <>
      <AppBar 
        position="fixed" 
        sx={{ 
          backgroundColor: '#000000',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo and Brand - Links to current city home */}
          <Box
            component={Link}
            to={getCityPath('')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}/logo.png`}
              alt="Nashville Fitness Logo"
              style={{
                height: '40px',
                marginRight: '10px'
              }}
              onError={(e) => {
                e.target.onerror = null;
                console.error('Failed to load logo from S3');
              }}
            />
          </Box>

          {/* Navigation Links - City aware */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="inherit"
              component={Link}
              to={getCityPath('/events')}
              startIcon={<EventIcon />}
              sx={{ mx: 1 }}
            >
              Events
            </Button>
            <Button
              color="inherit"
              component={Link}
              to={getCityPath('/directory')}
              startIcon={<StorefrontIcon />}
              sx={{ mx: 1 }}
            >
              Directory
            </Button>
          </Box>

          {/* Auth Section */}
          <Box>
            {!user ? (
              <Box>
                <Button 
                  color="inherit" 
                  component={Link} 
                  to={getCityPath('/login')}
                >
                  Login
                </Button>
              </Box>
            ) : (
              <Box>
                <IconButton
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    src={user.profile_picture}
                    alt={user.first_name}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {user && isAdmin() && (
                    <MenuItem 
                      component={Link} 
                      to={`/${currentCity}/dashboard`} // Change this to use city context
                      onClick={handleClose}
                    >
                      Admin Dashboard
                    </MenuItem>
                  )}
                  <MenuItem 
                    component={Link} 
                    to={getCityPath('/profile')} 
                    onClick={handleClose}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Spacer */}
    </>
  );
}

export default Navbar;