import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Alert,
  CircularProgress,
  ImageList,
  ImageListItem,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/AuthContext';
import { apiClient } from '../context/AuthContext';
import { useParams } from 'react-router-dom';

function Settings() {
  const { user } = useAuth();
  const { city } = useParams();
  const currentCity = city || 'nashville';
  
  const [settings, setSettings] = useState({
    banner_text: '',
    meta_description: '',
    contact_email: '',
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
  });
  const [heroImages, setHeroImages] = useState([]);
  const [message, setMessage] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  // Add these new state variables
  const [mediaTypes, setMediaTypes] = useState([]);
  const [mediaTypeLoading, setMediaTypeLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('newsletter');
  const [mediaName, setMediaName] = useState('');
  const [mediaDescription, setMediaDescription] = useState('');

  useEffect(() => {
    fetchSettings();
    fetchMediaTypes(); // Add this line

  }, [currentCity]);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/${currentCity}/settings/site-settings/`);
      console.log('Settings response:', response.data);
      
      setSettings({
        banner_text: response.data.banner_text || '',
        meta_description: response.data.meta_description || '',
        contact_email: response.data.contact_email || '',
        facebook_url: response.data.facebook_url || '',
        instagram_url: response.data.instagram_url || '',
        twitter_url: response.data.twitter_url || '',
      });
      
      if (response.data?.hero_images) {
        setHeroImages(response.data.hero_images);
      } else {
        console.warn('No hero images found in response');
        setHeroImages([]);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setMessage({
        type: 'error',
        text: 'Failed to load current settings'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSaveSettings = async () => {
    try {
      setSaving(true);
      await apiClient.put(`/${currentCity}/settings/site-settings/`, settings);
      setMessage({
        type: 'success',
        text: 'Settings updated successfully!'
      });
    } catch (error) {
      console.error('Save error:', error);
      setMessage({
        type: 'error',
        text: error.response?.data?.detail || 'Failed to save settings'
      });
    } finally {
      setSaving(false);
    }
  };

  const handleImageUpload = async (e) => {
    if (!e.target.files?.length) return;
    
    setUploadLoading(true);
    setMessage(null);

    const data = new FormData();
    data.append('hero_image', e.target.files[0]);

    try {
      const response = await apiClient.put(
        `/${currentCity}/settings/site-settings/`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      await fetchSettings();
      
      setMessage({ type: 'success', text: 'Hero image uploaded successfully!' });
      e.target.value = '';
    } catch (error) {
      console.error('Upload error:', error);
      setMessage({
        type: 'error',
        text: error.response?.data?.error || 'Failed to upload image'
      });
    } finally {
      setUploadLoading(false);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      await apiClient.delete(`/${currentCity}/settings/hero-images/${imageId}/`);
      setHeroImages(prev => prev.filter(img => img.id !== imageId));
      setMessage({ 
        type: 'success', 
        text: 'Image deleted successfully!' 
      });
    } catch (error) {
      console.error('Delete error:', error);
      setMessage({
        type: 'error',
        text: 'Failed to delete image'
      });
    }
  };

  const handleToggleActive = async (imageId, currentStatus) => {
    try {
      await apiClient.patch(
        `/${currentCity}/settings/hero-images/${imageId}/`,
        { is_active: !currentStatus }
      );
      
      setHeroImages(prev => prev.map(img => 
        img.id === imageId ? { ...img, is_active: !currentStatus } : img
      ));
      
      setMessage({ 
        type: 'success', 
        text: `Image ${!currentStatus ? 'activated' : 'deactivated'} successfully!` 
      });
    } catch (error) {
      console.error('Toggle error:', error);
      setMessage({
        type: 'error',
        text: 'Failed to update image status'
      });
    }
  };

  const handleBulkUpdateActive = async (imageStates) => {
    try {
      await apiClient.post(`/${currentCity}/settings/hero-images/bulk/`, {
        image_states: imageStates
      });
      await fetchSettings();
      setMessage({
        type: 'success',
        text: 'Images updated successfully!'
      });
    } catch (error) {
      console.error('Bulk update error:', error);
      setMessage({
        type: 'error',
        text: 'Failed to update images'
      });
    }
  };

  const fetchMediaTypes = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/settings/media-types/`);
      setMediaTypes(response.data);
    } catch (error) {
      console.error('Error fetching media types:', error);
      setMessage({
        type: 'error',
        text: 'Failed to load media types'
      });
    }
  };

  const handleMediaUpload = async (e) => {
    if (!e.target.files?.length) return;
    if (!mediaName.trim()) {
      setMessage({
        type: 'error',
        text: 'Please provide a name for the media'
      });
      return;
    }
    
    setMediaTypeLoading(true);
    setMessage(null);
  
    const data = new FormData();
    data.append('image', e.target.files[0]);
    data.append('category', selectedCategory);
    data.append('name', mediaName.trim());
    data.append('description', mediaDescription.trim());
  
    try {
      await apiClient.post(
        `/${currentCity}/settings/media-types/`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
  
      await fetchMediaTypes();
      setMediaName('');
      setMediaDescription('');
      setMessage({ type: 'success', text: 'Media uploaded successfully!' });
      e.target.value = '';
    } catch (error) {
      console.error('Upload error:', error);
      setMessage({
        type: 'error',
        text: error.response?.data?.error || 'Failed to upload media'
      });
    } finally {
      setMediaTypeLoading(false);
    }
  };
  
  const handleDeleteMedia = async (mediaId) => {
    try {
      await apiClient.delete(`/${currentCity}/settings/media-types/${mediaId}/`);
      setMediaTypes(prev => prev.filter(media => media.id !== mediaId));
      setMessage({ 
        type: 'success', 
        text: 'Media deleted successfully!' 
      });
    } catch (error) {
      console.error('Delete error:', error);
      setMessage({
        type: 'error',
        text: 'Failed to delete media'
      });
    }
  };
  
  const handleToggleMediaActive = async (mediaId, currentStatus) => {
    try {
      await apiClient.patch(
        `/${currentCity}/settings/media-types/${mediaId}/`,
        { is_active: !currentStatus }
      );
      
      setMediaTypes(prev => prev.map(media => 
        media.id === mediaId ? { ...media, is_active: !currentStatus } : media
      ));
      
      setMessage({ 
        type: 'success', 
        text: `Media ${!currentStatus ? 'activated' : 'deactivated'} successfully!` 
      });
    } catch (error) {
      console.error('Toggle error:', error);
      setMessage({
        type: 'error',
        text: 'Failed to update media status'
      });
    }
  };

  if (!user?.is_staff) {
    return (
      <Container maxWidth="sm">
        <Paper sx={{ p: 4, mt: 4 }}>
          <Typography variant="h6" color="error">
            Access Denied: Admin privileges required
          </Typography>
        </Paper>
      </Container>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Site Settings - {currentCity}
        </Typography>

        {message && (
          <Alert
            severity={message.type}
            sx={{ mb: 2 }}
            onClose={() => setMessage(null)}
          >
            {message.text}
          </Alert>
        )}

        {/* General Settings */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Banner Text"
              name="banner_text"
              value={settings.banner_text}
              onChange={handleSettingChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Meta Description"
              name="meta_description"
              value={settings.meta_description}
              onChange={handleSettingChange}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Contact Email"
              name="contact_email"
              type="email"
              value={settings.contact_email}
              onChange={handleSettingChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Facebook URL"
              name="facebook_url"
              value={settings.facebook_url}
              onChange={handleSettingChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Instagram URL"
              name="instagram_url"
              value={settings.instagram_url}
              onChange={handleSettingChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Twitter URL"
              name="twitter_url"
              value={settings.twitter_url}
              onChange={handleSettingChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSaveSettings}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : 'Save Settings'}
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Hero Images
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            component="label"
            disabled={uploadLoading}
          >
            {uploadLoading ? <CircularProgress size={24} /> : 'Upload New Hero Image'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
        </Box>

        {heroImages.length > 0 ? (
          <ImageList cols={3} gap={16}>
            {heroImages.map((image) => (
              <ImageListItem 
                key={`hero-image-${image.id}`}
                sx={{ 
                  overflow: 'hidden',
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'divider',
                  height: '250px !important'
                }}
              >
                <img
                  src={image.optimized_url}
                  alt={`Hero ${image.id}`}
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={image.is_active}
                        onChange={() => handleToggleActive(image.id, image.is_active)}
                        color="primary"
                        size="small"
                      />
                    }
                    label="Active"
                    sx={{ 
                      color: 'white',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '0.875rem'
                      }
                    }}
                  />
                  <IconButton
                    onClick={() => handleDeleteImage(image.id)}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Typography color="text.secondary" align="center">
            No hero images uploaded yet.
          </Typography>
        )}
        {/* Media Types Section */}
          <Typography variant="h5" gutterBottom sx={{ mt: 6 }}>
            Media Assets
          </Typography>

          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  value={selectedCategory}
                  label="Category"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value="newsletter">Newsletter</MenuItem>
                  <MenuItem value="social">Social Media</MenuItem>
                  <MenuItem value="banner">Banner</MenuItem>
                  <MenuItem value="logo">Logo</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Name"
                value={mediaName}
                onChange={(e) => setMediaName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                label="Description"
                value={mediaDescription}
                onChange={(e) => setMediaDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                disabled={mediaTypeLoading || !mediaName}
              >
                {mediaTypeLoading ? <CircularProgress size={24} /> : 'Upload New Media'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleMediaUpload}
                />
              </Button>
            </Grid>
          </Grid>

          {mediaTypes.length > 0 ? (
            <ImageList cols={3} gap={16}>
              {mediaTypes.map((media) => (
                <ImageListItem 
                  key={`media-${media.id}`}
                  sx={{ 
                    overflow: 'hidden',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    height: '250px !important'
                  }}
                >
                  <img
                    src={media.optimized_url}
                    alt={media.name}
                    loading="lazy"
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover'
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      bgcolor: 'rgba(0, 0, 0, 0.7)',
                      p: 1
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
                      {media.name} ({media.category})
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={media.is_active}
                            onChange={() => handleToggleMediaActive(media.id, media.is_active)}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Active"
                        sx={{ 
                          color: 'white',
                          '& .MuiFormControlLabel-label': {
                            fontSize: '0.875rem'
                          }
                        }}
                      />
                      <IconButton
                        onClick={() => handleDeleteMedia(media.id)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Typography color="text.secondary" align="center">
              No media assets uploaded yet.
            </Typography>
          )}
      </Paper>
    </Container>
  );
}

export default Settings;