import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Box, Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';

function GenerateNewsletter() {
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const generateNewsletter = async () => {
      try {
        setLoading(true);
        setError('');
        
        console.log(`Generating newsletter for ${currentCity}...`);
        const response = await apiClient.post(`/${currentCity}/newsletters/generate/`);
        
        console.log('Newsletter generation response:', response);
        
        if (response.data) {
          setSuccess(true);
          setTimeout(() => {
            navigate(`/${currentCity}/newsletters/manage`);
          }, 2000);
        } else {
          throw new Error('No data received from server');
        }
      } catch (error) {
        console.error('Error details:', error.response || error);
        const errorMessage = error.response?.data?.detail || 
                           error.response?.data?.error ||
                           error.message ||
                           'Failed to generate newsletter';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    generateNewsletter();
  }, [currentCity, navigate]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          {loading ? 'Generating Newsletter...' : success ? 'Newsletter Generated!' : 'Generation Failed'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Newsletter generated successfully! Redirecting...
          </Alert>
        )}

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default GenerateNewsletter;