import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Switch,           
  FormControlLabel
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';

function EditEvent() {
  const { slug, city } = useParams(); // Changed from id to slug
  const currentCity = city || 'nashville';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    location: '',
    date_time: '',
    image: null,
    link: '',
    featured: false

  });

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        console.log(`Fetching event: ${currentCity}/events/${slug}`);
        const response = await apiClient.get(`/${currentCity}/events/${slug}/`);
        console.log('Event data:', response.data);
        
        // Format the date for the datetime-local input
        const eventDate = new Date(response.data.date_time);
        const formattedDate = eventDate.toISOString().slice(0, 16);
  
        setFormData({
          name: response.data.name || '',
          description: response.data.description || '',
          location: response.data.location || '',
          date_time: formattedDate,
          image: null,
          link: response.data.link || '',
          featured: response.data.featured || false  // Add this line
        });
  
        if (response.data.image) {
          setCurrentImage(response.data.image);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        setError('Failed to fetch event details');
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvent();
  }, [slug, currentCity]);

  const [currentImage, setCurrentImage] = useState(null);

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const data = new FormData();
      
      // Only append fields that have values
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== '') {
          // Skip the image if it hasn't changed
          if (key === 'image' && typeof formData[key] === 'string') {
            return;
          }
          data.append(key, formData[key]);
        }
      });
  
      console.log(`Updating event: ${currentCity}/events/${slug}`);
      await apiClient.patch(`/${currentCity}/events/${slug}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      navigate(`/${currentCity}/events/manage`);
    } catch (error) {
      console.error('Update error:', error);
      setError(
        error.response?.data?.detail || 
        error.response?.data?.message || 
        'Failed to update event'
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Edit Event
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Event Name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="location"
                label="Location"
                value={formData.location}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="link"
                label="Link"
                value={formData.link}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="date_time"
                label="Date and Time"
                type="datetime-local"
                value={formData.date_time}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
  <FormControlLabel
    control={
      <Switch
        checked={formData.featured}
        onChange={handleChange}
        name="featured"
        color="primary"
        disabled={loading}
      />
    }
    label="Featured Event"
  />
</Grid>
            <Grid item xs={12}>
              {currentImage && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Current Image:
                  </Typography>
                  <img 
                    src={currentImage} 
                    alt="Event" 
                    style={{ maxWidth: '200px', height: 'auto' }} 
                  />
                </Box>
              )}

              <Button
                variant="contained"
                component="label"
                sx={{ mr: 2 }}
                disabled={loading}
              >
                {currentImage ? 'Change Image' : 'Upload Image'}
                <input
                  type="file"
                  hidden
                  name="image"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Button>
              {formData.image && typeof formData.image !== 'string' && (
                <Typography variant="body2" component="span">
                  New image selected: {formData.image.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Update Event'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/${currentCity}/events/manage`)}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default EditEvent;