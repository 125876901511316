import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  MenuItem,
  FormControlLabel,
  Switch,
  Grid
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const EditPost = () => {
  const { slug, city } = useParams(); // Changed from id to slug
  const currentCity = city || 'nashville';
  const navigate = useNavigate();
  

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['blockquote', 'link'],
      ['clean']
    ]
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'blockquote', 'link'
  ];

  const [formData, setFormData] = useState({
    title: '',
    content: '',
    type: '',
    link: '',
    image: null,
    featured: false
  });
  const [postTypes, setPostTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    const fetchPostTypes = async () => {
      try {
        const response = await apiClient.get(`/${currentCity}/posts/types/`);
        console.log('Post types:', response.data);
        setPostTypes(response.data);
      } catch (error) {
        console.error('Error fetching post types:', error);
        setError('Error loading post types');
      }
    };

    fetchPostTypes();
  }, [currentCity]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        console.log(`Fetching post with slug: ${slug}`);
        const response = await apiClient.get(`/${currentCity}/posts/${slug}`);
        console.log('Post data:', response.data);
        setFormData({
          title: response.data.title,
          content: response.data.content,
          type: response.data.type.id,
          link: response.data.link || '',
          image: null,
          featured: response.data.featured || false
        });
        setCurrentImage(response.data.image_url);
      } catch (error) {
        console.error('Error fetching post:', error);
        setError('Error fetching post details');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, currentCity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        image: e.target.files[0]
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const data = new FormData();
    
    Object.keys(formData).forEach(key => {
      if (formData[key] !== null) {
        if (key === 'image' && typeof formData[key] === 'string') {
          return;
        }
        data.append(key, formData[key]);
      }
    });

    try {
      console.log(`Updating post with slug: ${slug}`);
      await apiClient.patch(`/${currentCity}/posts/${slug}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      navigate(`/${currentCity}/posts/manage`);
    } catch (error) {
      console.error('Error updating post:', error);
      setError(error.response?.data?.detail || 'Failed to update post');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Post
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Post Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            disabled={loading}
          />

          <Box sx={{ mt: 2, mb: 2 }}>
  <Typography variant="subtitle2" gutterBottom>
    Content *
  </Typography>
  <ReactQuill
    theme="snow"
    value={formData.content}
    onChange={(content) => setFormData(prev => ({ ...prev, content }))}
    modules={modules}
    formats={formats}
    style={{ height: '200px', marginBottom: '50px' }}
  />
  {error && !formData.content && (
    <Typography color="error" variant="caption">
      Content is required
    </Typography>
  )}
</Box>

          <TextField
            select
            margin="normal"
            required
            fullWidth
            id="type"
            label="Post Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            disabled={loading}
          >
            {postTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            fullWidth
            id="link"
            label="External Link (Optional)"
            name="link"
            value={formData.link || ''}
            onChange={handleChange}
            helperText="Add affiliate links, sources, or resources"
            placeholder="https://example.com"
            type="url"
            disabled={loading}
          />

          {currentImage && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Current Image:
              </Typography>
              <img 
                src={currentImage} 
                alt="Current post" 
                style={{ maxWidth: '200px', height: 'auto' }} 
              />
            </Box>
          )}

          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              {currentImage ? 'Change Image:' : 'Add Image:'}
            </Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              disabled={loading}
              style={{ display: 'block', width: '100%' }}
            />
          </Box>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.featured}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    featured: e.target.checked
                  }))}
                  name="featured"
                  color="primary"
                  disabled={loading}
                />
              }
              label="Featured Post"
            />
          </Grid>

          <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ flex: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Update Post'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate(`/${currentCity}/posts/manage`)}
              sx={{ flex: 1 }}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default EditPost;