import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  Link as MuiLink
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// Replace with your actual Google Maps API key
const MAPS_API_KEY = 'AIzaSyC8VbW6WXr9KdW_w_n9yOSVxNq6NPpLKJM';

function EventDetail() {
  const { city, slug } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${city}/events/${slug}/`);
        setEvent(response.data);
      } catch (err) {
        setError('Error loading event details');
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [city, slug]);

  const generateSEOData = (event) => ({
    title: `${event.name} in ${event.city_name} | REBL Fit Life`,
    description: event.description?.length > 155 
      ? `${event.description.slice(0, 155)}...` 
      : event.description || `Join us for ${event.name} at ${event.location} in ${event.city_name}`,
    image: event.image,
    url: window.location.href,
    type: 'event'
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography color="error">{error}</Typography>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={() => navigate(`/${city}/events`)}
            sx={{ mt: 2 }}
          >
            Back to Events
          </Button>
        </Paper>
      </Container>
    );
  }

  if (!event) return null;

  const seoData = generateSEOData(event);
  const eventDate = new Date(event.date_time);
  const formattedDate = eventDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric', 
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  });
  
  const formattedTime = eventDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC'
  });

  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`;
  const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(event.location)}&key=${MAPS_API_KEY}`;

  return (
    <>



      <Container maxWidth="lg" sx={{ mt: 4, mb: 6 }}>
  <Button 
    startIcon={<ArrowBackIcon />} 
    onClick={() => navigate(`/${city}/events`)}
    sx={{ mb: 3 }}
  >
    Back to Events
  </Button>

  {/* Event Name at top */}
  <Typography variant="h4" gutterBottom>
    {event.name}
  </Typography>

  <Grid container spacing={4}>
    {/* Left Column: Image and About stacked */}
    <Grid item xs={12} md={8}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Card>
            {event.image && (
              <CardMedia
                component="img"
                height="400"
                image={`${event.image}`}
                alt={event.name}
                sx={{ objectFit: 'cover' }}
              />
            )}
          </Card>
        </Grid>

        <Grid item>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              About This Event
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mb: 2 }}>
              {event.description}
            </Typography>
            {event.link && (
              <Button
                variant="contained"
                color="primary"
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mt: 2 }}
                fullWidth
              >
                View Event Details
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Grid>

    {/* Right Column: Address & Map */}
    <Grid item xs={12} md={4}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ my: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography>{formattedDate}</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography>{formattedTime}</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2 }}>
            <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
            <MuiLink 
              href={mapsUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="hover"
              sx={{ color: '#1e90ff', overflow: 'hidden', maxWidth: '100%' }}
            >
              {event.location}
            </MuiLink>
          </Box>
        </Box>

        {/* Maps Embed */}
        <Box sx={{ mt: 3 }}>
          <iframe
            title="event-location-map"
            width="100%"
            height="350"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src={embedUrl}
          ></iframe>
        </Box>
      </Paper>
    </Grid>
  </Grid>
</Container>

    </>
  );
}

export default EventDetail;
