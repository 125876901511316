import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Snackbar,
  Alert,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://nashvillefitness.up.railway.app';

function Newsletters() {
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const { city } = useParams();
  const currentCity = city || 'nashville';
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    newsletter: null
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchNewsletters();
  }, [currentCity]);

  const fetchNewsletters = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/newsletters/`);
      setNewsletters(response.data);
    } catch (error) {
      console.error('Error fetching newsletters:', error);
      showSnackbarMessage('Failed to load newsletters', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showSnackbarMessage = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const openDeleteDialog = (newsletter) => {
    setDeleteDialog({ open: true, newsletter });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, newsletter: null });
  };

  const handleDeleteConfirm = async () => {
    const newsletter = deleteDialog.newsletter;
    if (!newsletter?.id) {
      showSnackbarMessage('Error: Newsletter identifier missing', 'error');
      closeDeleteDialog();
      return;
    }
  
    try {
      // Update URL to match backend route pattern
      await apiClient.delete(`/${currentCity}/newsletters/${newsletter.id}/view/`);
      setNewsletters(prev => prev.filter(n => n.id !== newsletter.id));
      showSnackbarMessage('Newsletter deleted successfully');
    } catch (error) {
      console.error('Error deleting newsletter:', error);
      showSnackbarMessage(
        `Failed to delete newsletter: ${error.response?.data?.detail || error.message}`,
        'error'
      );
    } finally {
      closeDeleteDialog();
    }
  };

  const copyHtmlContent = async (newsletter) => {
    try {
      if (!newsletter.content) {
        throw new Error('Newsletter content not found');
      }
      
      await navigator.clipboard.writeText(newsletter.content);
      setCopySuccess(true);
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopySuccess(false);
    }
  };

  const viewNewsletter = (newsletterId) => {
    window.open(`${BACKEND_URL}/api/${currentCity}/newsletters/${newsletterId}/view/`, '_blank');
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ mb: 3, flexDirection: { xs: 'column', sm: 'row' } }}
      >
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">Newsletters</Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/${currentCity}/newsletters/generate`}
            startIcon={<AddIcon />}
            fullWidth
          >
            Generate Newsletter
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newsletters.map((newsletter) => (
              <TableRow key={newsletter.id}>
                <TableCell>
                  {newsletter.thumbnail_url ? (
                    <Box
                      component="img"
                      src={newsletter.thumbnail_url}
                      alt={newsletter.title}
                      sx={{
                        width: 80,
                        height: 60,
                        objectFit: 'cover',
                        borderRadius: 1,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 80,
                        height: 60,
                        bgcolor: 'grey.200',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        No image
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell>{newsletter.title}</TableCell>
                <TableCell>
                  {new Date(newsletter.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="View Newsletter">
                    <IconButton onClick={() => viewNewsletter(newsletter.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy HTML Content">
                    <IconButton onClick={() => copyHtmlContent(newsletter)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Newsletter">
                    <IconButton 
                      onClick={() => openDeleteDialog(newsletter)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={deleteDialog.open}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Delete Newsletter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the newsletter "{deleteDialog.newsletter?.title}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Newsletters;