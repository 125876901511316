import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Divider,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { apiClient } from '../context/AuthContext';

const CompanyDetail = () => {
  const { slug, city } = useParams(); // Capture city and slug dynamically
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        console.log(`Fetching company for city: ${city}, slug: ${slug}`);
        const response = await apiClient.get(`/${city}/companies/${slug}/`);
        console.log('API Response:', response.data);
        setCompany(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error details:', {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status,
          url: err.config?.url
        });
        setError('Failed to load company details');
        setLoading(false);
      }
    };

    fetchCompany();
  }, [city, slug]);

  const generateSEOData = (company) => ({
    title: `${company.name} in ${company.city_name} | REBL Fit Life`,
    description: company.description?.length > 155 
      ? `${company.description.slice(0, 155)}...` 
      : company.description || `Learn more about ${company.name} in ${company.city_name}.`,
    image: company.logo_url || company.featured_image_url,
    url: window.location.href,
    type: 'company',
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !company) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography color="error" align="center">
          {error || 'Company not found'}
        </Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mt: 2 }}
        >
          Back to Directory
        </Button>
      </Container>
    );
  }

  const seoData = generateSEOData(company);

  return (
    <>
  

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mb: 4 }}
        >
          Back to Directory
        </Button>

        <Paper sx={{ overflow: 'hidden', position: 'relative' }}>
          {company.featured_image_url && (
            <Box
              sx={{
                height: 300,
                width: '100%',
                backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${company.featured_image_url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
              }}
            >
              {company.logo_url && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 'calc(100% - 22px)',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    border: '4px solid white',
                    backgroundColor: 'white',
                    zIndex: 10,
                  }}
                >
                  <img
                    src={company.logo_url}
                    alt={`${company.name} Logo`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box sx={{ p: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              {company.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" align="center" sx={{ mb: 4 }}>
              {company.type_name}
            </Typography>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                About
              </Typography>
              <Typography paragraph>{company.description}</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper variant="outlined" sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                {company.address && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <LocationOnIcon sx={{ mr: 2, color: 'primary.main' }} />
                    <Typography>{company.address}</Typography>
                  </Box>
                )}
                {company.phone && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <PhoneIcon sx={{ mr: 2, color: 'primary.main' }} />
                    <Typography>
                      <a href={`tel:${company.phone}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        {company.phone}
                      </a>
                    </Typography>
                  </Box>
                )}
                {company.email && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <EmailIcon sx={{ mr: 2, color: 'primary.main' }} />
                    <Typography>
                      <a href={`mailto:${company.email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        {company.email}
                      </a>
                    </Typography>
                  </Box>
                )}
                <Box sx={{ mt: 3 }}>
                  {company.website && (
                    <IconButton href={company.website} target="_blank" rel="noopener noreferrer" color="primary">
                      <LanguageIcon />
                    </IconButton>
                  )}
                  {company.instagram && (
                    <IconButton href={company.instagram} target="_blank" rel="noopener noreferrer" color="primary">
                      <InstagramIcon />
                    </IconButton>
                  )}
                  {company.facebook && (
                    <IconButton href={company.facebook} target="_blank" rel="noopener noreferrer" color="primary">
                      <FacebookIcon />
                    </IconButton>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
    </>
  );
};

export default CompanyDetail;
