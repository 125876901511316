// EventList.js
import React, { useState, useEffect } from 'react';
import { Grid, Box, CircularProgress, Typography } from '@mui/material';
import EventCard from './EventCard';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function EventList({ city }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${apiUrl}/${city}/events/`);
        
        // Filter out past events
        const currentDateTime = new Date();
        const upcomingEvents = response.data.filter(event => {
          const eventDateTime = new Date(event.date_time);
          return eventDateTime >= currentDateTime;
        });

        // No need to sort since backend already sorts by date_time descending
        // Just reverse the array to get ascending order (soonest first)
        const sortedUpcomingEvents = upcomingEvents.reverse();

        setEvents(sortedUpcomingEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to load events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [city]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (events.length === 0) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography align="center">
          No upcoming events found in {city}.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={4}>
      {events.map(event => (
        <Grid item xs={12} sm={6} md={4} key={event.slug}> 
          <EventCard 
            event={event} 
            city={city}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default EventList;