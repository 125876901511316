import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography, 
  Box, 
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CompanyCard from '../components/CompanyCard';
import { apiClient } from '../context/AuthContext';

const CompanyList = () => {
  const { city } = useParams(); // Capture the city slug dynamically
  const [companies, setCompanies] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [heroImage, setHeroImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companiesRes, typesRes, settingsRes] = await Promise.all([
          apiClient.get(`/${city}/companies/`),
          apiClient.get(`/${city}/companies/types/`),
          apiClient.get(`/${city}/settings/site-settings/`),
        ]);

        setCompanies(companiesRes.data);
        setCompanyTypes(typesRes.data);

        // Handle hero images
        if (settingsRes.data?.hero_images?.length > 0) {
          const activeImages = settingsRes.data.hero_images.filter((img) => img.is_active);
          if (activeImages.length > 0) {
            const randomImage = activeImages[Math.floor(Math.random() * activeImages.length)];
            setHeroImage(randomImage.optimized_url || randomImage.mobile_url);
          }
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data.');
        setLoading(false);
      }
    };

    fetchData();
  }, [city]);

  useEffect(() => {
    const fetchFilteredCompanies = async () => {
      try {
        let url = `/${city}/companies/`;
        if (selectedType) {
          url += `?type=${selectedType}`; // Use type ID for filtering
        }
        console.log(`Fetching companies with URL: ${url}`); // Debug URL
        const response = await apiClient.get(url);
        console.log('Filtered companies response:', response.data); // Debug response
        setCompanies(response.data);
      } catch (err) {
        console.error('Error fetching filtered companies:', err);
      }
    };

    fetchFilteredCompanies();
  }, [city, selectedType]);

  const handleTypeClick = (typeSlug) => {
    console.log('Selected type:', typeSlug); // Debug selected type
    setSelectedType(typeSlug === selectedType ? null : typeSlug);
  };

  const filteredCompanies = companies.filter((company) => 
    company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalButtons = companyTypes.length + 1;
  const buttonWidth = `${100 / totalButtons}%`;

  const buttonStyles = {
    width: buttonWidth,
    minWidth: 'unset',
    px: { xs: 1, sm: 2 },
    fontSize: { xs: '0.75rem', sm: '0.875rem' },
    textTransform: 'none',
    borderColor: '#E0E0E0',
    '&:hover': {
      borderColor: '#757575',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  };

  const selectedButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#424242',
    color: 'white',
    '&:hover': {
      backgroundColor: '#616161',
    },
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          mb: 6,
          background: heroImage
            ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${heroImage}")`
            : 'primary.main',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '250px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginBottom: '0px',
        }}
      >
        <Container maxWidth="md" sx={{ position: 'relative', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
          <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 3, fontSize: { xs: '2.5rem', md: '3.75rem' } }}>
            {city.charAt(0).toUpperCase() + city.slice(1)} Fitness Directory
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ maxWidth: '600px', mx: 'auto', mb: 4, fontSize: { xs: '1.25rem', md: '1.5rem' } }}>
            Discover Fitness Companies in {city.charAt(0).toUpperCase() + city.slice(1)}
          </Typography>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, mx: 'auto', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: { xs: 0.5, sm: 1 } }}>
            <Button
              variant={selectedType === null ? "contained" : "outlined"}
              onClick={() => handleTypeClick(null)}
              sx={selectedType === null ? selectedButtonStyles : buttonStyles}
            >
              All
            </Button>
            {companyTypes.map((type) => (
              <Button
                key={type.id}
                variant={selectedType === type.id ? "contained" : "outlined"}
                onClick={() => handleTypeClick(type.id)}
                sx={selectedType === type.id ? selectedButtonStyles : buttonStyles}
              >
                {type.name}
              </Button>
            ))}
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {filteredCompanies.length === 0 ? (
          <Typography align="center" sx={{ mt: 4 }}>
            No companies found.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {filteredCompanies.map((company) => (
              <Grid item xs={12} sm={6} md={4} key={company.id}>
                <CompanyCard company={company} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default CompanyList;
