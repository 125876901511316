import React from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate,
  useParams,
  useNavigate
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navbar from './components/Navbar';
import AdminLayout from './components/AdminLayout';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import EventDetail from './pages/EventDetail';
import CreateEvent from './pages/CreateEvent';
import Events from './pages/admin/Events';
import EditEvent from './pages/admin/EditEvent';
import Newsletters from './pages/admin/Newsletters';
import GenerateNewsletter from './pages/admin/GenerateNewsletter';
import Settings from './pages/Settings';
import CompanyList from './pages/CompanyList';
import CompanyDetail from './pages/CompanyDetail';
import Posts from './pages/admin/Posts';
import EditPost from './pages/admin/EditPost';
import CreatePost from './pages/admin/CreatePost';
import PublicNewsletter from './pages/PublicNewsletter';
import Companies from './pages/admin/Companies';
import CreateCompany from './pages/admin/CreateCompany';
import EditCompany from './pages/admin/EditCompany';
import PostDetail from './pages/PostDetail';  // Add this line

// ProtectedRoute Component
function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';

  React.useEffect(() => {
    if (!loading && !user) {
      navigate(`/${currentCity}/login`);
    }
  }, [loading, user, navigate, currentCity]);

  if (loading) return <div>Loading...</div>;
  if (!user) return null;

  return children;
}

// AdminRoute Component
function AdminRoute({ children }) {
  const { user, loading, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';

  React.useEffect(() => {
    if (!loading && (!user || !isAdmin())) {
      navigate(`/${currentCity}/login`);
    }
  }, [loading, user, isAdmin, navigate, currentCity]);

  if (loading) return <div>Loading...</div>;
  if (!user || !isAdmin()) return null;

  return children;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});

function App() {
  return (

    <>

    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Navbar />
          <Routes>
            {/* Root Redirect */}
            <Route path="/" element={<Navigate to="/nashville" replace />} />

            {/* City-Based Routes */}
            <Route path="/:city" element={<Navigate to="events" replace />} />
            <Route path="/:city/login" element={<Login />} />
            <Route path="/:city/signup" element={<Signup />} />
            <Route path="/:city/events" element={<Home />} />
            <Route path="/:city/events/:slug" element={<EventDetail />} />
            <Route path="/:city/newsletters/view/:id" element={<PublicNewsletter />} />
            {/* Protected User Routes */}
            <Route
              path="/:city/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />

            {/* Admin Routes */}
            <Route
              path="/:city/dashboard"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Dashboard />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/newsletters/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Newsletters />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/newsletters/generate"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <GenerateNewsletter />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            {/* Posts Routes */}
            <Route
              path="/:city/posts/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Posts />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/posts/create"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <CreatePost />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/posts/edit/:slug"  // Changed from :id to :slug
              element={
                <AdminRoute>
                  <AdminLayout>
                    <EditPost />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            // Change it to:
            <Route
              path="/:city/events/edit/:slug"  // Changed from :id to :slug
              element={
                <AdminRoute>
                  <AdminLayout>
                    <EditEvent />
                  </AdminLayout>
                </AdminRoute>
              }
            />

            {/* Events Routes */}
            <Route
              path="/:city/events/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Events />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/events/edit/:id"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <EditEvent />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/events/create"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <CreateEvent />
                  </AdminLayout>
                </AdminRoute>
              }
            />

            {/* Newsletter Routes */}
            <Route
              path="/:city/newsletters/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Newsletters />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/newsletters/generate"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <GenerateNewsletter />
                  </AdminLayout>
                </AdminRoute>
              }
            />
            // Add these routes in App.js within the admin routes section:

            {/* Companies Routes */}
            <Route
              path="/:city/companies/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Companies />  {/* You'll need to import this */}
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/companies/create"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <CreateCompany />  {/* You'll need to import this */}
                  </AdminLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/:city/companies/edit/:slug"  // Note: using slug instead of id
              element={
                <AdminRoute>
                  <AdminLayout>
                    <EditCompany />  {/* You'll need to import this */}
                  </AdminLayout>
                </AdminRoute>
              }
            />

            {/* Settings Route */}
            <Route
              path="/:city/settings/manage"
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Settings />
                  </AdminLayout>
                </AdminRoute>
              }
            />

            {/* Public Routes */}
            <Route path="/:city/gyms" element={<CompanyList type="gym" />} />
            <Route path="/:city/company/:slug" element={<CompanyDetail />} />
            <Route path="/:city/directory" element={<CompanyList />} />
            <Route path="/:city/directory/:typeSlug" element={<CompanyList />} />
            <Route path="/:city/newsletters/:slug" element={<Newsletters />} />
            <Route path="/:city/newsletters/view/:id" element={<PublicNewsletter />} />
            <Route path="/:city/posts/:slug" element={<PostDetail />} />

            {/* Redirect old admin routes to city-based routes */}
            <Route 
              path="/admin/*" 
              element={<Navigate to="/nashville/dashboard" replace />} 
            />

            {/* Catch-all Route for 404 */}
            <Route
              path="*"
              element={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                >
                  <h1>404 - Page Not Found</h1>
                </div>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
    </>

  );
}

export default App;