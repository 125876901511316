import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// Create a custom Axios instance
export const apiClient = axios.create({
  baseURL: apiUrl,
  maxBodyLength: Infinity,
  maxContentLength: Infinity
});

// Helper to get current city from URL
const getCurrentCity = () => {
  const pathname = window.location.pathname;
  const cityMatch = pathname.match(/^\/([^/]+)/);
  return cityMatch ? cityMatch[1] : 'nashville';
};

// Add request interceptor
apiClient.interceptors.request.use((config) => {
  // Add Authorization token if available
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Handle content type and FormData
  if (config.data instanceof FormData) {
    delete config.headers['Content-Type']; // Let browser set this
    config.headers = {
      ...config.headers,
      'Accept': 'application/json',
    };
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  // Determine if the city slug should be added
  const shouldAddCitySlug = config.addCitySlug !== false;

  if (shouldAddCitySlug) {
    const city = getCurrentCity();
    if (!config.url.includes(`/${city}/`)) {
      config.url = `/${city}${config.url.startsWith('/') ? config.url : '/' + config.url}`;
    }
  }

  // Debug logging in development
  if (process.env.NODE_ENV === 'development') {
    console.log('Request:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      isFormData: config.data instanceof FormData
    });

    if (config.data instanceof FormData) {
      console.log('FormData contents:');
      for (let pair of config.data.entries()) {
        console.log(pair[0], pair[1] instanceof File ? `File: ${pair[1].name}` : pair[1]);
      }
    }
  }

  return config;
});

// Add response interceptor
apiClient.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data
      });
    }
    return response;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error('API Error:', {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
    }
    return Promise.reject(error);
  }
);

// Create AuthContext
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await apiClient.get('/users/profile/', {
            addCitySlug: false
          });
          setUser(response.data);
        } catch (error) {
          console.error('Auth check failed:', error);
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await apiClient.post(
        '/users/login/',
        { email, password },
        { addCitySlug: false }
      );

      if (response.data.user && response.data.token) {
        setUser(response.data.user);
        localStorage.setItem('token', response.data.token);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  const isAdmin = () => {
    return user?.is_staff === true;
  };

  const updateUser = (userData) => {
    setUser(userData);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
        isAdmin,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook for using auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// File upload helpers
export const uploadFile = async (url, formData, options = {}) => {
  try {
    const response = await apiClient.post(url, formData, {
      headers: {
        ...options.headers
      },
      onUploadProgress: options.onProgress
    });
    return response.data;
  } catch (error) {
    console.error('File upload error:', error);
    throw error;
  }
};

export const validateFile = (file, options = {}) => {
  const {
    maxSize = 5 * 1024 * 1024, // 5MB default
    allowedTypes = ['image/jpeg', 'image/png', 'image/gif'],
    fieldName = 'File'
  } = options;

  if (!file) {
    throw new Error(`${fieldName} is required`);
  }

  if (file.size > maxSize) {
    throw new Error(`${fieldName} size must be less than ${Math.round(maxSize / (1024 * 1024))}MB`);
  }

  if (!allowedTypes.includes(file.type)) {
    throw new Error(`${fieldName} must be one of the following types: ${allowedTypes.join(', ')}`);
  }

  return true;
};

// Helper function for handling form data
export const createFormDataWithFiles = (data) => {
  const formData = new FormData();
  
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (value !== null && value !== undefined) {
      formData.append(key, typeof value === 'boolean' ? value.toString() : value);
    }
  });
  
  return formData;
};