import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { Link, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';
import { FormControlLabel, Switch } from '@mui/material';

function Events() {
  const { city } = useParams();
  const currentCity = city || 'nashville';

  // State management
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [sortConfig, setSortConfig] = useState({
    field: 'date_time',
    direction: 'asc',
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    event: null
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [activeFilters, setActiveFilters] = useState([]);

  // Fetch events on component mount and city change
  useEffect(() => {
    fetchEvents();
  }, [currentCity]);

  // Apply filters when relevant state changes
  useEffect(() => {
    applyFiltersAndSearch();
  }, [events, searchTerm, dateRange, sortConfig]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log(`Fetching events for city: ${currentCity}`);
      const response = await apiClient.get(`/${currentCity}/events/`);
      
      if (!Array.isArray(response.data)) {
        throw new Error('Expected array of events');
      }

      setEvents(response.data);
      setFilteredEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to load events');
      showSnackbarMessage('Failed to load events', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showSnackbarMessage = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const openDeleteDialog = (event) => {
    if (!event.slug) {
      showSnackbarMessage('Error: Cannot delete event without identifier', 'error');
      return;
    }
    setDeleteDialog({ open: true, event });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, event: null });
  };
  const handleToggleFeatured = async (slug, currentFeatured) => {
    try {
      await apiClient.patch(`/${currentCity}/events/${slug}/`, {
        featured: !currentFeatured
      });
      
      // Update local state
      setFilteredEvents(prev => prev.map(event => 
        event.slug === slug ? { ...event, featured: !currentFeatured } : event
      ));
      setEvents(prev => prev.map(event => 
        event.slug === slug ? { ...event, featured: !currentFeatured } : event
      ));
      
      showSnackbarMessage(`Event ${!currentFeatured ? 'featured' : 'unfeatured'} successfully`);
    } catch (error) {
      console.error('Error toggling featured status:', error);
      showSnackbarMessage('Failed to update featured status', 'error');
    }
  };
  const handleDeleteConfirm = async () => {
    const event = deleteDialog.event;
    if (!event?.slug) {
      showSnackbarMessage('Error: Event identifier missing', 'error');
      closeDeleteDialog();
      return;
    }

    try {
      console.log(`Deleting event with slug: ${event.slug}`);
      await apiClient.delete(`/${currentCity}/events/${event.slug}/`);
      
      // Update local state
      setEvents(prev => prev.filter(e => e.slug !== event.slug));
      setFilteredEvents(prev => prev.filter(e => e.slug !== event.slug));
      showSnackbarMessage('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
      showSnackbarMessage(
        `Failed to delete event: ${error.response?.data?.detail || error.message}`, 
        'error'
      );
    } finally {
      closeDeleteDialog();
    }
  };

  const applyFiltersAndSearch = () => {
    let filtered = [...events];

    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(event =>
        event.name.toLowerCase().includes(searchLower) ||
        event.location.toLowerCase().includes(searchLower)
      );
    }

    // Apply date filters
    if (dateRange.startDate) {
      filtered = filtered.filter(event => 
        new Date(event.date_time) >= dateRange.startDate
      );
    }
    if (dateRange.endDate) {
      filtered = filtered.filter(event => 
        new Date(event.date_time) <= dateRange.endDate
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortConfig.field) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'date_time':
          comparison = new Date(a.date_time) - new Date(b.date_time);
          break;
        default:
          comparison = 0;
      }
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });

    setFilteredEvents(filtered);
    updateActiveFilters();
  };

  const updateActiveFilters = () => {
    const filters = [];
    if (searchTerm) filters.push(`Search: ${searchTerm}`);
    if (dateRange.startDate) filters.push(`From: ${dateRange.startDate.toLocaleDateString()}`);
    if (dateRange.endDate) filters.push(`To: ${dateRange.endDate.toLocaleDateString()}`);
    if (sortConfig.field !== 'date_time' || sortConfig.direction !== 'asc') {
      filters.push(`Sort: ${sortConfig.field} ${sortConfig.direction}`);
    }
    setActiveFilters(filters);
  };

  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const clearFilters = () => {
    setSearchTerm('');
    setDateRange({ startDate: null, endDate: null });
    setSortConfig({ field: 'date_time', direction: 'asc' });
    setActiveFilters([]);
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>Loading events...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={fetchEvents} variant="contained" sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        {/* Header */}
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">Events</Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/${currentCity}/events/create`}
          >
            Create Event
          </Button>
        </Box>

        {/* Filters Panel */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search events..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                fullWidth
                startIcon={<FilterListIcon />}
                onClick={() => setShowFilters(!showFilters)}
                variant={showFilters ? "contained" : "outlined"}
              >
                Filters
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortConfig.field}
                  label="Sort By"
                  onChange={(e) => handleSort(e.target.value)}
                >
                  <MenuItem value="date_time">Date</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Date Filters */}
          <Collapse in={showFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Start Date"
                    value={dateRange.startDate}
                    onChange={(date) => setDateRange(prev => ({ ...prev, startDate: date }))}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="End Date"
                    value={dateRange.endDate}
                    onChange={(date) => setDateRange(prev => ({ ...prev, endDate: date }))}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {/* Active Filters */}
          {activeFilters.length > 0 && (
            <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {activeFilters.map((filter, index) => (
                <Chip
                  key={index}
                  label={filter}
                  size="small"
                />
              ))}
              <Button size="small" onClick={clearFilters}>
                Clear All
              </Button>
            </Box>
          )}
        </Paper>

        {/* Events Table */}
        {filteredEvents.length === 0 ? (
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography>No events found</Typography>
          </Paper>
        ) : (
          <TableContainer component={Paper}>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Featured</TableCell> {/* Add this line */}
                <TableCell>Date & Time</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

              <TableBody>
                {filteredEvents.map((event) => (
                  <TableRow key={event.slug}>
                    <TableCell>
                      <Link
                        to={`/${currentCity}/events/${event.slug}`}
                        style={{ 
                          textDecoration: 'none', 
                          color: 'primary.main',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                        component={Link}
                      >
                        {event.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={event.featured}
                            onChange={() => handleToggleFeatured(event.slug, event.featured)}
                            color="primary"
                            size="small"
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    <TableCell>
                      {(() => {
                        const eventDate = new Date(event.date_time);
                        const formattedDate = eventDate.toLocaleDateString('en-US', {
                          year: 'numeric', 
                          month: 'long',
                          day: 'numeric',
                          timeZone: 'UTC'
                        });
                        const formattedTime = eventDate.toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                          timeZone: 'UTC'
                        });
                        return `${formattedDate} ${formattedTime}`;
                      })()}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={Link}
                        to={`/${currentCity}/events/edit/${event.slug}`}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => openDeleteDialog(event)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialog.open}
          onClose={closeDeleteDialog}
        >
          <DialogTitle>Delete Event</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the event "{deleteDialog.event?.name}"?
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog}>Cancel</Button>
            <Button 
              onClick={handleDeleteConfirm} 
              color="error" 
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
}

export default Events;