import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { apiClient } from '../context/AuthContext';
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EventStatistics from '../components/EventStatistics';

export default function Dashboard() {
  const { user } = useAuth();
  const { city } = useParams();
  const currentCity = city || 'nashville';
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await apiClient.get(`/${currentCity}/events/`);
        const upcomingEvents = response.data
          .filter(event => new Date(event.date_time) >= new Date())
          .sort((a, b) => new Date(a.date_time) - new Date(b.date_time))
          .slice(0, 5);
        setEvents(upcomingEvents);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [currentCity]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC'
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        p: 3,
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      {/* Welcome Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Welcome back, {user?.first_name || 'User'}
        </Typography>
        <Typography color="text.secondary">
          Here's what's happening in {currentCity}
        </Typography>
      </Box>

      {/* Statistics Section */}
      <Box sx={{ mb: 6 }}>
        <EventStatistics />
      </Box>

      {/* Main Content Section */}
      <Grid container spacing={3}>
        {/* Upcoming Events Section */}
        <Grid item xs={12} md={8}>
          <Card 
            elevation={1}
            sx={{ 
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 2,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box sx={{ 
              pb: 2, 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
              <Typography variant="h5">Upcoming Events</Typography>
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            </Box>
            <CardContent sx={{ flex: 1, p: 0 }}>
              <List>
                {events.map((event, index) => (
                  <React.Fragment key={event.slug}>
                    <ListItem 
                      component={Link}
                      to={`/${currentCity}/events/${event.slug}`}
                      sx={{ 
                        display: 'block', 
                        py: 2,
                        px: 3,
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {event.name}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <CalendarTodayIcon sx={{ fontSize: 18, mr: 1, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {formatDate(event.date_time)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOnIcon sx={{ fontSize: 18, mr: 1, color: 'text.secondary' }} />
                        <Typography variant="body2" color="text.secondary">
                          {event.location}
                        </Typography>
                      </Box>
                    </ListItem>
                    {index < events.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Quick Links Section */}
        <Grid item xs={12} md={4}>
          <Card 
            elevation={1}
            sx={{ 
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 2,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box sx={{ 
              pb: 2,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
            }}>
              <Typography variant="h5">Quick Links</Typography>
            </Box>
            <CardContent sx={{ flex: 1, p: 0 }}>
              <List>
                {[
                  {
                    title: "Create New Event",
                    description: "Add a new event to the calendar",
                    link: `/${currentCity}/events/create`
                  },
                  {
                    title: "Generate Newsletter",
                    description: "Create new newsletter content",
                    link: `/${currentCity}/newsletters/generate`
                  },
                  {
                    title: "Create New Post",
                    description: "Add a new blog post",
                    link: `/${currentCity}/posts/create`
                  }
                ].map((item, index, arr) => (
                  <React.Fragment key={item.title}>
                    <ListItem 
                      component={Link} 
                      to={item.link}
                      sx={{ 
                        display: 'block',
                        textDecoration: 'none',
                        color: 'inherit',
                        py: 2,
                        px: 3,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </ListItem>
                    {index < arr.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
