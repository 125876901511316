import React from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography,
  CardActionArea,
  Box,
  Chip
} from '@mui/material';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const CompanyCard = ({ company }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardActionArea 
        component={Link} 
        to={`/nashville/company/${company.slug}`}
        sx={{ flexGrow: 1 }}
      >
        <Box sx={{ position: 'relative' }}>
          {company.featured_image_thumbnail_url && (
            <CardMedia
              component="img"
              height="200"
              image={company.featured_image_thumbnail_url}
              alt={company.name}
              sx={{ objectFit: 'cover' }}
            />
          )}
          {company.logo_url && (
            <Box
              sx={{
                position: 'absolute',
                bottom: -40,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
                width: 80,
                height: 80,
                borderRadius: '50%',
                bgcolor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 1,
                padding: '4px',
              }}
            >
              <Box
                component="img"
                src={company.logo_url}
                alt={`${company.name} logo`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '50%',
                }}
              />
            </Box>
          )}
        </Box>
        
        <CardContent sx={{ pt: company.logo_url ? 5 : 2 }}>
          <Typography 
            gutterBottom 
            variant="h6" 
            component="div" 
            align="center"
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: 1.2,
              minHeight: '2.4em',
              mt: 1
            }}
          >
            {company.name}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LocationOnIcon sx={{ fontSize: 20, mr: 1, color: 'primary.main' }} />
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {company.address}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FitnessCenterIcon sx={{ fontSize: 20, mr: 1, color: 'primary.main' }} />
            <Typography variant="body2" color="text.secondary">
              {company.type_name}
            </Typography>
          </Box>

          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {company.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CompanyCard;