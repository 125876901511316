// src/pages/CreateEvent.js
import React, { useState } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Alert,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../context/AuthContext';  // Update path if needed

function CreateEvent() {
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    location: '',
    date_time: '',
    image: null,
    link: '',
    featured: false 

  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const data = new FormData();
      
      // Validate required fields
      if (!formData.name || !formData.description || !formData.location || !formData.date_time) {
        setError('Please fill in all required fields');
        setLoading(false);
        return;
      }
  
      // Handle the date directly
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== '') {
          if (key === 'date_time') {
            // Create a new Date object and send as ISO string
            const date = new Date(formData.date_time);
            data.append(key, date.toISOString());
          } else {
            data.append(key, formData[key]);
          }
        }
      });
  
      const response = await apiClient.post(`/${currentCity}/events/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data) {
        navigate(`/${currentCity}/events/manage`);
      }
    } catch (error) {
      console.error('Error creating event:', error);
      
      // More detailed error handling
      if (error.response) {
        // The server responded with an error
        const errorMessage = error.response.data.detail || 
                            error.response.data.error ||
                            Object.values(error.response.data).flat().join(', ') ||
                            'Failed to create event';
        setError(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        setError('No response from server. Please try again.');
      } else {
        // Something happened in setting up the request
        setError('Error creating event. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to handle cancel
  const handleCancel = () => {
    navigate(`/${currentCity}/events/manage`); // Updated navigation path
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create New Event
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Event Name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="location"
                label="Location"
                value={formData.location}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="link"
                label="Link"
                value={formData.link}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="date_time"
                label="Date and Time"
                type="datetime-local"
                value={formData.date_time}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.featured}
                onChange={handleChange}
                name="featured"
                color="primary"
              />
            }
            label="Featured Event"
          />
        </Grid>
            
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                sx={{ mr: 2 }}
                disabled={loading}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  name="image"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Button>
              {formData.image && (
                <Typography variant="body2" component="span">
                  Selected: {formData.image.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={loading}
                >
                  {loading ? 'Creating...' : 'Create Event'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  size="large"
                  sx={{ ml: 2 }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateEvent;