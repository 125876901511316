import React from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../components/Hero';
import EventList from '../components/EventList';
import { Container } from '@mui/material';

function Home() {
  const { city } = useParams(); // Get city from route parameters

  return (
    <>
      <Hero city={city} />
      <Container maxWidth="lg">
        <EventList city={city} /> {/* Pass city to EventList */}
      </Container>
    </>
  );
}

export default Home;
