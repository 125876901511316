import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  Chip,
  Grid,
  Divider,
  Link as MuiLink
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import { apiClient } from '../context/AuthContext';
import LaunchIcon from '@mui/icons-material/Launch';

const richTextStyles = {
  '& h1': {
    fontSize: '2em',
    marginBottom: '0.67em',
  },
  '& h2': {
    fontSize: '1.5em',
    marginBottom: '0.75em',
  },
  '& ul, & ol': {
    paddingLeft: '2em',
    marginBottom: '1em',
  },
  '& blockquote': {
    borderLeft: '4px solid #ccc',
    margin: '1.5em 10px',
    padding: '0.5em 10px',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  '& p': {
    marginBottom: '1em',
  },
  '& a': {
    color: 'primary.main',
    textDecoration: 'underline',
  },
  '& strong': {
    fontWeight: 600,
  },
  '& em': {
    fontStyle: 'italic',
  },
  lineHeight: 1.8,
  fontSize: '1.1rem',
};

function PostDetail() {
  const { slug, city } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentCity = city || 'nashville';

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await apiClient.get(`/${currentCity}/posts/${slug}`);
        
        if (!response.data) {
          throw new Error('Post not found');
        }
        
        setPost(response.data);
      } catch (err) {
        console.error('Error fetching post:', err);
        const errorMessage = err.response?.data?.detail || 'Failed to load post details';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    if (slug && currentCity) {
      fetchPost();
    }
  }, [slug, currentCity]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !post) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography color="error">{error || 'Post not found'}</Typography>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={() => navigate(`/${currentCity}/posts`)}
            sx={{ mt: 2 }}
          >
            Back to Posts
          </Button>
        </Paper>
      </Container>
    );
  }

  const formattedDate = new Date(post.created_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 6 }}>
      <Button 
        startIcon={<ArrowBackIcon />} 
        onClick={() => navigate(`/${currentCity}/posts/manage`)}
        sx={{ mb: 3 }}
      >
        Back to Posts
      </Button>

      <Grid container spacing={4}>
        {post.image_url && (
          <Grid item xs={12}>
            <Card>
              <CardMedia
                component="img"
                height="400"
                image={post.image_url}
                alt={post.title}
                sx={{ objectFit: 'cover' }}
              />
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper sx={{ p: 4 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h3" gutterBottom>
                {post.title}
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="body2" color="text.secondary">
                    {formattedDate}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="body2" color="text.secondary">
                    {post.author_name || 'Anonymous'}
                  </Typography>
                </Box>
                {post.type && (
                  <Chip 
                    label={post.type.name} 
                    color="primary" 
                    size="small" 
                    sx={{ ml: 'auto' }}
                  />
                )}
              </Box>

              <Divider sx={{ my: 3 }} />

              <Box 
                sx={richTextStyles}
                dangerouslySetInnerHTML={{ __html: post.content }}
              />

              {post.link && (
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    href={post.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<LaunchIcon />}
                    fullWidth
                    size="large"
                    sx={{ 
                      mt: 2,
                      py: 1.5 // Add some padding to make it taller
                    }}
                  >
                    Learn More
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PostDetail;