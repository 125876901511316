import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient, useAuth } from '../../context/AuthContext';

function CreateCompany() {
  const navigate = useNavigate();
  const { city } = useParams();
  const currentCity = city || 'nashville';
  const { user } = useAuth();  // Get the logged-in user
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [types, setTypes] = useState([]);
  
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    instagram: '',
    facebook: '',
    logo: null,
    featured_image: null,
    is_active: true
  });

  useEffect(() => {
    fetchCompanyTypes();
  }, [currentCity]);

  useEffect(() => {
    if (user && !user.city) {
      setError('Your profile does not have an associated city. Please update your profile.');
    }
  }, [user]);

  const fetchCompanyTypes = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/companies/types/`);
      setTypes(response.data);
    } catch (error) {
      console.error('Error fetching company types:', error);
      setError('Failed to load company types');
    }
  };

  const handleChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!user || !user.city) {
      setError('You must be logged in and have a city associated with your profile to create a company.');
      setLoading(false);
      return;
    }

    try {
      const data = new FormData();
      
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== '') {
          if (key === 'is_active') {
            data.append(key, formData[key].toString());
          } else {
            data.append(key, formData[key]);
          }
        }
      });

      // No need to append 'city' here

      await apiClient.post(`/${currentCity}/companies/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      navigate(`/${currentCity}/companies/manage`);
    } catch (error) {
      console.error('Error response:', error.response);
      setError(
        error.response?.data?.detail || 
        error.response?.data?.message || 
        'Failed to create company'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create Company
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Company Name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Company Type</InputLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  disabled={loading}
                  label="Company Type"
                >
                  {types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address"
                label="Address"
                value={formData.address}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="phone"
                label="Phone"
                value={formData.phone}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="website"
                label="Website"
                value={formData.website}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="instagram"
                label="Instagram URL"
                value={formData.instagram}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="facebook"
                label="Facebook URL"
                value={formData.facebook}
                onChange={handleChange}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                disabled={loading}
              >
                Upload Logo
                <input
                  type="file"
                  hidden
                  name="logo"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Button>
              {formData.logo && (
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Selected: {formData.logo.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                disabled={loading}
              >
                Upload Featured Image
                <input
                  type="file"
                  hidden
                  name="featured_image"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Button>
              {formData.featured_image && (
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Selected: {formData.featured_image.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_active}
                    onChange={handleChange}
                    name="is_active"
                    color="primary"
                    disabled={loading}
                  />
                }
                label="Active"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || !user || !user.city}
                >
                  {loading ? <CircularProgress size={24} /> : 'Create Company'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/${currentCity}/companies/manage`)}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateCompany;
