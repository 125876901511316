import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [types, setTypes] = useState([]);
  const { city } = useParams();
  const currentCity = city || 'nashville';

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    company: null
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    Promise.all([fetchCompanies(), fetchTypes()]);
  }, [currentCity]);

  useEffect(() => {
    applyFilters();
  }, [companies, searchTerm, selectedType]);

  const showSnackbarMessage = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const openDeleteDialog = (company) => {
    setDeleteDialog({ open: true, company });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, company: null });
  };

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/${currentCity}/companies/`);
      const data = Array.isArray(response.data) ? response.data : [];
      setCompanies(data);
      setFilteredCompanies(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Failed to load companies');
      showSnackbarMessage('Failed to load companies', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await apiClient.get(`/${currentCity}/companies/types/`);
      setTypes(response.data);
    } catch (error) {
      console.error('Error fetching types:', error);
      showSnackbarMessage('Failed to load company types', 'error');
    }
  };

  const handleDeleteConfirm = async () => {
    const company = deleteDialog.company;
    if (!company?.slug) {
      showSnackbarMessage('Error: Company identifier missing', 'error');
      closeDeleteDialog();
      return;
    }

    try {
      await apiClient.delete(`/${currentCity}/companies/${company.slug}/`);
      setCompanies(prev => prev.filter(c => c.slug !== company.slug));
      showSnackbarMessage('Company deleted successfully');
    } catch (error) {
      console.error('Error deleting company:', error);
      showSnackbarMessage(
        `Failed to delete company: ${error.response?.data?.detail || error.message}`,
        'error'
      );
    } finally {
      closeDeleteDialog();
    }
  };

  const handleToggleFeatured = async (slug, currentFeatured) => {
    try {
      await apiClient.patch(`/${currentCity}/companies/${slug}/`, {
        featured: !currentFeatured
      });
      
      setCompanies(prev => prev.map(company => 
        company.slug === slug ? { ...company, featured: !currentFeatured } : company
      ));
      
      showSnackbarMessage(`Company ${!currentFeatured ? 'featured' : 'unfeatured'} successfully`);
    } catch (error) {
      console.error('Error toggling featured status:', error);
      showSnackbarMessage('Failed to update featured status', 'error');
    }
  };

  const applyFilters = () => {
    let filtered = [...companies];

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(company =>
        company.name.toLowerCase().includes(searchLower) ||
        company.type_name.toLowerCase().includes(searchLower)
      );
    }

    if (selectedType) {
      filtered = filtered.filter(company => company.type === parseInt(selectedType));
    }

    setFilteredCompanies(filtered);
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>Loading companies...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={fetchCompanies} variant="contained" sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Companies</Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/${currentCity}/companies/create`}
        >
          Add Company
        </Button>
      </Box>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search companies..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Filter by Type</InputLabel>
              <Select
                value={selectedType}
                label="Filter by Type"
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <MenuItem value="">All Types</MenuItem>
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Logo</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Featured</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>
                  <Avatar
                    src={company.logo_url}
                    alt={company.name}
                    sx={{ width: 40, height: 40 }}
                  />
                </TableCell>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.type_name}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={company.featured}
                        onChange={() => handleToggleFeatured(company.slug, company.featured)}
                        color="primary"
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    component={Link}
                    to={`/${currentCity}/companies/edit/${company.slug}`}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => openDeleteDialog(company)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={deleteDialog.open}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Delete Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the company "{deleteDialog.company?.name}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Companies;