import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Link as MuiLink,
  FormControlLabel,
  Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import { apiClient } from '../../context/AuthContext';

function Posts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { city } = useParams();
  const currentCity = city || 'nashville';

  // Add new state for delete dialog and snackbar
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    post: null
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchPosts();
  }, [currentCity]);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/${currentCity}/posts/`);
      console.log('Fetched posts:', response.data);
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to load posts');
      showSnackbarMessage('Failed to load posts', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Helper function for showing snackbar messages
  const showSnackbarMessage = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  // Dialog handlers
  const openDeleteDialog = (post) => {
    setDeleteDialog({ open: true, post });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, post: null });
  };

  const handleToggleFeatured = async (slug, currentFeatured) => {
    try {
      await apiClient.patch(`/${currentCity}/posts/${slug}/`, {
        featured: !currentFeatured
      });
      
      // Update local state
      setPosts(prev => prev.map(post => 
        post.slug === slug ? { ...post, featured: !currentFeatured } : post
      ));
      
      showSnackbarMessage(`Post ${!currentFeatured ? 'featured' : 'unfeatured'} successfully`);
    } catch (error) {
      console.error('Error toggling featured status:', error);
      showSnackbarMessage('Failed to update featured status', 'error');
    }
  };
  const handleDeleteConfirm = async () => {
    const post = deleteDialog.post;
    if (!post?.slug) {
      showSnackbarMessage('Error: Post identifier missing', 'error');
      closeDeleteDialog();
      return;
    }

    try {
      await apiClient.delete(`/${currentCity}/posts/${post.slug}/`);
      setPosts(prev => prev.filter(p => p.slug !== post.slug));
      showSnackbarMessage('Post deleted successfully');
    } catch (error) {
      console.error('Error deleting post:', error);
      showSnackbarMessage(
        `Failed to delete post: ${error.response?.data?.detail || error.message}`,
        'error'
      );
    } finally {
      closeDeleteDialog();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>Loading posts...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={fetchPosts} variant="contained" sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Posts</Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/${currentCity}/posts/create`}
        >
          Create Post
        </Button>
      </Box>
      {posts.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No posts found</Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Featured</TableCell>

                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.map((post) => (
                <TableRow key={post.slug}>
                  <TableCell>
                    <MuiLink
                      component={Link}
                      to={`/${currentCity}/posts/${post.slug}`}
                      sx={{
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                        color: 'primary.main',
                        cursor: 'pointer'
                      }}
                    >
                      {post.title}
                    </MuiLink>
                  </TableCell>
                  <TableCell>{post.type?.name || 'No type'}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={post.featured}
                          onChange={() => handleToggleFeatured(post.slug, post.featured)}
                          color="primary"
                          size="small"
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={Link}
                      to={`/${currentCity}/posts/edit/${post.slug}`}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => openDeleteDialog(post)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Delete Post</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the post "{deleteDialog.post?.title}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Posts;